import { useEffect } from 'react'
import Spinner from '../../../components/Spinner/Index'
import { useNavigate } from 'react-router'
import { getSubscriptionStatus } from '../../../store/subscription/subscriptionReducer'
import { useDispatch, useSelector } from 'react-redux'

const SubscriptionSuccess = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { subscriptionStatus } = useSelector((state) => state.subscriptions)
  const { user } = useSelector((state) => state.auth)

  useEffect(() => {
    const getSubStatus = async () => {
      try {
        await dispatch(getSubscriptionStatus())
        if (subscriptionStatus === 'active') {
          navigate(`/buyer/${user?.username}`)
        } else {
          navigate('/buyer/subscription')
        }
      } catch (error) {
        console.log(error)
      }
    }
    getSubStatus()
  }, [])

  return <Spinner />
}
export default SubscriptionSuccess
