import { useEffect } from 'react'
import { subscriptionStatus } from '../../../store/subscription/subscriptionService'
import { useNavigate } from 'react-router'
import Spinner from '../../../components/Spinner/Index'
import { useSelector } from 'react-redux'

const SubscriptionCancel = () => {
  const navigate = useNavigate()
  const { subscriptions } = useSelector((state) => state.auth.user)
  const { user } = useSelector((state) => state.auth)

  useEffect(() => {
    const getSubscriptionStatus = async () => {
      try {
        const response = await subscriptionStatus()
        // console.log(response.data)
        if (response.data.subscriptions[0]?.status === 'active') {
          navigate(`/buyer/${user?.username}`)
        } else {
          navigate('/buyer/subscription')
        }
      } catch (error) {
        console.log(error)
      }
    }
    getSubscriptionStatus()
  }, [subscriptions])

  return <Spinner />
}
export default SubscriptionCancel
