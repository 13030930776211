import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { forgotPassword } from '../../../store/auth/authReducer'
import { useDispatch } from 'react-redux'
import { toast } from 'react-hot-toast'

type FormValues = {
  email: string
}

const ForgotPassword = () => {
  const form = useForm<FormValues>()
  const { register, handleSubmit, formState, reset } = form
  const { errors } = formState
  const dispatch = useDispatch()

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({ email: '' })
      toast.success('Please check your email')
      setTimeout(() => {
        window.location.href = '/auth/login'
      }, 2000)
    }
  }, [register, handleSubmit, formState, reset])

  const onSubmit = (data: FormValues) => {
    dispatch(forgotPassword(data))
  }

  return (
    <div className="flex min-h-screen flex-col justify-center sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-auto h-16 mx-auto text-purple-500"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"
          />
        </svg>

        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight dark:text-white text-gray-900">
          Please enter your email
        </h2>
        <p className="mt-2 text-center text-sm dark:text-white text-gray-600">
          Or{' '}
          <a
            href="/auth/login"
            className="font-medium text-purple-600 hover:text-purple-500"
          >
            start your 14-day free trial
          </a>
        </p>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white dark:bg-gray-950 dark:border dark:border-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium dark:text-white text-gray-700"
              >
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  type="email"
                  {...register('email', {
                    required: 'Email address is required',
                    pattern: {
                      value:
                        /([a-z0-9][-a-z0-9_\+\.]*[a-z0-9])@([a-z0-9][-a-z0-9\.]*[a-z0-9]\.(arpa|root|aero|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|bd|be|bf|bg|bh|bi|bj|bm|bn|bo|br|bs|bt|bv|bw|by|bz|ca|cc|cd|cf|cg|ch|ci|ck|cl|cm|cn|co|cr|cu|cv|cx|cy|cz|de|dj|dk|dm|do|dz|ec|ee|eg|er|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gb|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|lt|lu|lv|ly|ma|mc|md|mg|mh|mk|ml|mm|mn|mo|mp|mq|mr|ms|mt|mu|mv|mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|om|pa|pe|pf|pg|ph|pk|pl|pm|pn|pr|ps|pt|pw|py|qa|re|ro|ru|rw|sa|sb|sc|sd|se|sg|sh|si|sj|sk|sl|sm|sn|so|sr|st|su|sv|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tp|tr|tt|tv|tw|tz|ua|ug|uk|um|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|yt|yu|za|zm|zw)|([0-9]{1,3}\.{3}[0-9]{1,3}))/,
                      message: 'Please enter a valid email',
                    },
                    validate: {
                      notAdmin: (value) => {
                        return value !== 'admin@example.com' || 'Nice try!'
                      },
                    },
                  })}
                  className="block w-full appearance-none rounded-md border border-gray-300 dark:bg-gray-800 dark:text-white px-3 py-2 placeholder-gray-400 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500 sm:text-sm"
                />
                {errors.email && (
                  <span role="alert" className="text-red-500 text-sm">
                    {errors.email?.message}
                  </span>
                )}
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md border border-transparent bg-purple-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
              >
                Reset Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
export default ForgotPassword
