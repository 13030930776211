import { CheckIcon } from '@heroicons/react/20/solid'
import { createSubscription } from '../../store/subscription/subscriptionService'
import { useDispatch, useSelector } from 'react-redux'
import Profile from './SubscriptionDetails/Index'
import Spinner from '../../components/Spinner/Index'
import { useEffect } from 'react'
import { getSubscriptionStatus } from '../../store/subscription/subscriptionReducer'
import BuyerLayout from '../../components/Layout/BuyerLayout/Index'
import { RootState } from '../../store'
import BreadCrumb from '../../components/BreadCrumb/Index'
import Panel from '../../components/Panel/Index'

const tiers = [
  {
    name: 'Monthly',
    id: 'tier-monthly',
    href: '#',
    price: '$24.99',
    monthly: true,
    priceId: process.env.STRIPE_MONTHLY_PRICE_ID,
    description:
      "The perfect plan if you're just getting started with our product.",
    features: [
      '25 products',
      'Up to 10,000 subscribers',
      'Advanced analytics',
      '24-hour support response time',
    ],
    featured: false,
  },
  {
    name: 'Yearly',
    id: 'tier-yearly',
    href: '#',
    price: '$249.00',
    monthly: false,
    priceId: process.env.STRIPE_YEARLY_PRICE_ID,
    description: 'Dedicated support and infrastructure for your company.',
    features: [
      'Unlimited products',
      'Unlimited subscribers',
      'Advanced analytics',
      'Dedicated support representative',
      'Marketing automations',
      'Custom integrations',
    ],
    featured: true,
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Subscription = () => {
  const dispatch = useDispatch()
  const { token, user } = useSelector((state: RootState) => state.auth)
  const { isLoading, subscriptionStatus } = useSelector(
    (state) => state.subscriptions
  )

  useEffect(() => {
    dispatch(getSubscriptionStatus())
  }, [dispatch])

  const handleSubscription = async (e, priceId) => {
    e.preventDefault()
    const { data } = await createSubscription(token, priceId)
    window.open(data.url, '_self')
  }

  if (isLoading) return <Spinner />

  if (subscriptionStatus === 'active') return <Profile />

  return (
    <BuyerLayout>
      <BreadCrumb title="Subscription" url={`/buyer/${user?.username}`} />
      <Panel
        title="Subscription"
        description="Subscribe to a plan."
        url="/buyer/subscription"
        icon={CheckIcon}
      >
        <div className="h-full relative isolate px-6 py-14 sm:py-32 lg:px-8">
          <div
            className="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden px-36 blur-3xl"
            aria-hidden="true"
          ></div>
          <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
            <h2 className="text-base font-semibold leading-7 text-purple-600">
              Pricing
            </h2>
            <p className="mt-2 text-4xl font-bold tracking-tight dark:text-white text-gray-900 sm:text-5xl">
              The right price for you, whoever you are
            </p>
          </div>
          <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 dark:text-white text-gray-600">
            Unlock a world of possibilities and exclusive benefits – subscribe
            to our plan today and elevate your experience to new heights
          </p>
          <div className="mx-auto mt-16 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:mt-20 sm:gap-y-0 lg:max-w-4xl lg:grid-cols-2">
            {tiers.map((tier, tierIdx) => (
              <div
                key={tier.id}
                className={classNames(
                  tier.featured
                    ? 'relative bg-white dark:bg-gray-950 dark:border dark:border-white shadow-2xl'
                    : 'bg-white/60 dark:bg-gray-950 dark:border dark:border-white sm:mx-8 lg:mx-0',
                  tier.featured
                    ? ''
                    : tierIdx === 0
                    ? 'rounded-t-3xl sm:rounded-b-none lg:rounded-tr-none lg:rounded-bl-3xl'
                    : 'sm:rounded-t-none lg:rounded-tr-3xl lg:rounded-bl-none',
                  'rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10'
                )}
              >
                <h3
                  id={tier.id}
                  className="text-base font-semibold leading-7 text-purple-600"
                >
                  {tier.name}
                </h3>
                <p className="mt-4 flex items-baseline gap-x-2">
                  <span className="text-5xl font-bold tracking-tight dark:text-white text-gray-900">
                    {tier.price}
                  </span>
                  <span className="text-base dark:text-white text-gray-500">
                    /{tier.monthly ? 'Monthly' : 'Yearly'}
                  </span>
                </p>
                <p className="mt-6 text-base leading-7 dark:text-white text-gray-600">
                  {tier.description}
                </p>
                <ul className="mt-8 space-y-3 text-sm leading-6 dark:text-white text-gray-600 sm:mt-10">
                  {tier.features.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <CheckIcon
                        className="h-6 w-5 flex-none text-purple-600"
                        aria-hidden="true"
                      />
                      {feature}
                    </li>
                  ))}
                </ul>
                <button
                  onClick={(e) => handleSubscription(e, tier.priceId)}
                  aria-describedby={tier.id}
                  className={classNames(
                    tier.featured
                      ? 'bg-purple-600 text-white shadow hover:bg-purple-500'
                      : 'text-purple-600 ring-1 ring-inset ring-purple-200 hover:ring-purple-300',
                    'mt-8 block rounded-md py-2.5 px-3.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600 sm:mt-10'
                  )}
                >
                  Get started today
                </button>
              </div>
            ))}
          </div>
        </div>
      </Panel>
    </BuyerLayout>
  )
}

export default Subscription
