import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  resetPassword,
  verifyPasswordResetToken,
} from '../../../store/auth/authReducer'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Spinner from '../../../components/Spinner/Index'
import { RootState } from '../../../store'
import toast from 'react-hot-toast'

type FormValues = {
  password: string
  confirm_password: string
}

const ResetPassword = () => {
  const { isLoading, isSuccess, isError, message } = useSelector(
    (state: RootState) => state.auth
  )
  const form = useForm<FormValues>()
  const { register, handleSubmit, formState, watch, getValues } = form
  const { errors, isDirty, isValid } = formState
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const id = searchParams.get('id')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(verifyPasswordResetToken({ token, id }))

    if (isError) {
      toast.error(message)
      navigate('/auth/login')
    }

    if (isLoading) {
      ;<div>
        <Spinner>Verifying your password reset token</Spinner>
      </div>
    }

    if (!token || !id) {
      toast.success('You followed an invalid link')
      navigate('/auth/login')
    }
  }, [])

  const onSubmit = (values: FormValues) => {
    dispatch(resetPassword({ ...values, token, id }))
  }

  if (isSuccess) {
    toast.success('Your password has been reset')
    navigate('/auth/login')
  }

  return (
    <div className="flex min-h-screen flex-col justify-center sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-auto h-16 mx-auto text-purple-500"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"
          />
        </svg>

        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
          Please update your password
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          Or{' '}
          <a
            href="/auth/login"
            className="font-medium text-purple-600 hover:text-purple-500"
          >
            start your 14-day free trial
          </a>
        </p>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  type="password"
                  {...register('password', {
                    minLength: {
                      value: 8,
                      message: 'Password must have at least 8 characters',
                    },
                    required: 'Please enter your password',
                  })}
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500 sm:text-sm"
                />
                {errors.password && (
                  <span role="alert" className="text-red-500 text-sm">
                    {errors.password?.message}
                  </span>
                )}
              </div>
            </div>
            <div>
              <label
                htmlFor="confirm_password"
                className="block text-sm font-medium text-gray-700"
              >
                Confirm Password
              </label>
              <div className="mt-1">
                <input
                  id="confirm_password"
                  type="password"
                  {...register('confirm_password', {
                    minLength: {
                      value: 8,
                      message: 'Password must have at least 8 characters',
                    },
                    required: 'Please enter your password',
                  })}
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500 sm:text-sm"
                />
                {watch('confirm_password') !== watch('password') &&
                getValues('confirm_password') ? (
                  <span role="alert" className="text-red-500 text-sm">
                    Password does not match
                  </span>
                ) : null}
                {errors.password && (
                  <span role="alert" className="text-red-500 text-sm">
                    {errors.password?.message}
                  </span>
                )}
              </div>
            </div>
            <div>
              <button
                disabled={!isDirty || !isValid}
                type="submit"
                className={
                  isDirty
                    ? 'flex w-full justify-center rounded-md border border-transparent bg-purple-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 disabled:bg-gray-300'
                    : 'flex w-full justify-center rounded-md border border-transparent bg-purple-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2'
                }
              >
                Update Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
export default ResetPassword
