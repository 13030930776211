import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { login, reset } from '../../../store/auth/authReducer'
import * as yup from 'yup'
import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { Mixpanel } from '../../../utils/MixPanel'
import { useFormik } from 'formik'
import { EyeIcon } from '@heroicons/react/24/outline'
import GuestLayout from '../../../components/Layout/GuestLayout/Index'

const Schema = yup.object({
  email: yup.string().email().required('Email is required').lowercase(),
  password: yup
    .string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
})

type FormValues = {
  email: string
  password: string
}

const Login = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false)
  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  )

  const { handleSubmit, handleChange, values, errors, touched, handleBlur } =
    useFormik({
      initialValues: {
        email: '',
        password: '',
      },
      validationSchema: Schema,
      onSubmit: (values: FormValues) => {
        dispatch(login(values))
      },
    })

  useEffect(() => {
    if (user) navigate(`/buyer/${user?.username}`)

    if (isSuccess) {
      Mixpanel.track('User Logged In', { email: user?.email })
      if (!user?.isVerified) {
        navigate(`/auth/verify-email`)
      }
      if (user?.role === 'ROLE_ADMIN') {
        navigate('/admin/')
      } else {
        navigate(`/buyer/${user?.username}`)
      }
    }

    if (isError && errors) {
      Mixpanel.track('Error', { message })
      toast.error(message)
      dispatch(reset())
    }
  }, [isSuccess, navigate, isError])

  return (
    <GuestLayout>
      <div className="flex min-h-screen flex-col justify-center sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-auto h-16 mx-auto text-purple-500"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"
            />
          </svg>

          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight dark:text-white text-gray-900">
            Sign in to your account
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600 dark:text-white">
            Or{' '}
            <Link
              to="/auth/register"
              className="font-medium text-purple-600 hover:text-purple-500"
            >
              start your 14-day free trial
            </Link>
          </p>
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white dark:bg-gray-950 dark:border dark:border-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 dark:text-white"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    type="email"
                    name="email"
                    autoComplete="email"
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className="block w-full appearance-none rounded-md border border-gray-300 dark:bg-gray-800 dark:text-white px-3 py-2 placeholder-gray-400 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500 sm:text-sm"
                  />
                  {touched.email && errors.email ? (
                    <span role="alert" className="text-red-500 text-sm">
                      {errors.email}
                    </span>
                  ) : null}
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700 dark:text-white"
                >
                  Password
                </label>
                <div className="mt-1">
                  <div className="flex items-center">
                    <input
                      id="password"
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      value={values.password}
                      onBlur={handleBlur}
                      autoComplete="current-password"
                      onChange={handleChange}
                      className="relative block w-full appearance-none rounded-md border border-gray-300 dark:bg-gray-800 dark:text-white px-3 py-2 placeholder-gray-400 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500 sm:text-sm"
                    />
                    <span
                      className="flex justify-around items-center"
                      role="button"
                      tabIndex={0} // Add this tabIndex attribute
                      onClick={() => {
                        setShowPassword(!showPassword)
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          setShowPassword(!showPassword)
                        }
                      }}
                    >
                      <EyeIcon className="cursor-pointer absolute h-6 w-6 float-left mr-10 dark:text-white" />
                    </span>
                  </div>
                  {touched.password && errors.password ? (
                    <span role="alert" className="text-red-500 text-sm">
                      {errors.password}
                    </span>
                  ) : message ? (
                    <span role="alert" className="text-red-500 text-sm">
                      {message}
                    </span>
                  ) : null}
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-purple-600 focus:ring-purple-500"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm dark:text-white text-gray-900"
                  >
                    Remember me
                  </label>
                </div>

                <div className="text-sm">
                  <Link
                    to="/auth/forgot-password"
                    className="font-medium text-purple-600 hover:text-purple-500"
                  >
                    Forgot your password?
                  </Link>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={isLoading}
                  className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500`}
                >
                  {isLoading ? (
                    <div role="status">
                      <svg
                        aria-hidden="true"
                        className="w-8 h-8 text-gray-900 animate-spin dark:text-purple-600 fill-white"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <span className="ml-2">Sign in</span>
                  )}
                </button>
              </div>
            </form>
            <div className="mt-4"></div>
          </div>
        </div>
      </div>
    </GuestLayout>
  )
}
export default Login
